export function INIT_DOODLE_ANIMATION() {
  const ANIMATED_DOODLES = document.querySelectorAll('.animated-doodle'),
    OBSERVER_OPTIONS = {rootMargin: '0px 0px 0px 0px'};

  const OBSERVER = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting === true) entry.target.classList.add('is--animated');
      //else entry.target.classList.remove('is--animated');  TODO: @Jannik, warum Kommentar? Nötig oder nicht?
    });
  }, OBSERVER_OPTIONS);

  ANIMATED_DOODLES.forEach(ANIMATED_DOODLE => OBSERVER.observe(ANIMATED_DOODLE));
}

import Collapse from "bootstrap/js/src/collapse";

const HEADER = document.querySelector('header');
const NAV_MAX_WIDTH = '1399px';

function HAS_NAV_MAX_WIDTH() {
  return window.matchMedia(`(max-width: ${NAV_MAX_WIDTH})`);
}

function SET_INDICATION_WHEN_SCROLLED(header) {
  if (!header) return;
  if (window.window.scrollY > 50) {
    if (!header.getAttribute('data-scrolled')) header.setAttribute('data-scrolled', 'true');
  } else header.removeAttribute('data-scrolled');
}

function INIT_SCROLL_INDICATION() {
  SET_INDICATION_WHEN_SCROLLED(HEADER);
  window.addEventListener('scroll', () => SET_INDICATION_WHEN_SCROLLED(HEADER));
}

function INIT_TOGGLER_LISTENER() {
  const COLLAPSE_NAV = document.querySelector('.navbar-collapse');
  COLLAPSE_NAV.addEventListener('show.bs.collapse', () => {
    if (!HEADER.getAttribute('data-expanded')) HEADER.setAttribute('data-expanded', 'true');
  });

  COLLAPSE_NAV.addEventListener('hide.bs.collapse', () => {
    if (HEADER.getAttribute('data-expanded')) HEADER.removeAttribute('data-expanded');
  })
}


function INIT_HEADER_DROPDOWN_ANIMATION() {
  const HEADER_DROPDOWN_TOGGLES = document.querySelectorAll('header [data-bs-toggle="dropdown"]');

  HEADER_DROPDOWN_TOGGLES.forEach(DROPDOWN_TOGGLE => {
    const DROPDOWN_MENU = DROPDOWN_TOGGLE.nextElementSibling;

    DROPDOWN_TOGGLE.addEventListener('show.bs.dropdown', () => {
      if (HAS_NAV_MAX_WIDTH().matches) {
        const MEGA_MENU = DROPDOWN_MENU.querySelector('.mega-menu'),
          MEGA_MENU_HEIGHT = MEGA_MENU.getBoundingClientRect().height + 'px',
          MEGA_MENU_MARGIN_TOP = window.getComputedStyle(MEGA_MENU).marginTop;
        DROPDOWN_MENU.style.maxHeight = `calc(${MEGA_MENU_HEIGHT} + ${MEGA_MENU_MARGIN_TOP} + ${MEGA_MENU_MARGIN_TOP})`;
      }
    })

    DROPDOWN_TOGGLE.addEventListener('hide.bs.dropdown', () => {
      if (HAS_NAV_MAX_WIDTH().matches) DROPDOWN_MENU.style.maxHeight = 0;
    })
  })
}

function INIT_RESET_MOBILE_NAV() {
  document.addEventListener('click', e => {
    e.stopPropagation()
    if (e.target.closest('.navbar') === null) {
      const NAVBAR_COLLAPSE = document.querySelector('.navbar-collapse');
      if (HAS_NAV_MAX_WIDTH().matches && NAVBAR_COLLAPSE.classList.contains('show')) {
        Collapse.getOrCreateInstance(NAVBAR_COLLAPSE).hide();
      }
    }
  })
}

export function INIT_NAVIGATION() {
  INIT_TOGGLER_LISTENER();
  INIT_SCROLL_INDICATION();
  INIT_HEADER_DROPDOWN_ANIMATION();
  INIT_RESET_MOBILE_NAV();
}

import '../../src/index';

import {INIT_PAGES_SWIPER_PORTRAIT} from "./js/inits/init.swipers";
import {INIT_PAGES_SWIPER_LANDSCAPE} from "./js/inits/init.swipers";

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    document.documentElement.classList.replace('no-js', 'js-rules');
  }, 90);

  INIT_PAGES_SWIPER_PORTRAIT();
  INIT_PAGES_SWIPER_LANDSCAPE();
})

import Dropdown from "bootstrap/js/src/dropdown";
import Collapse from "bootstrap/js/src/collapse";

import {INIT_NAVIGATION} from "./js/inits/init.navigation";
import {INIT_RELLAX} from "./js/inits/init.rellax";
import {INIT_DOODLE_ANIMATION} from "./js/inits/init.draw";
document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    document.documentElement.classList.replace('no-js', 'js-rules');
  }, 90);

  INIT_NAVIGATION();
  INIT_RELLAX();
  INIT_DOODLE_ANIMATION();
})

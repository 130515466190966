import Swiper from "swiper";
import {Navigation, Scrollbar, FreeMode, Mousewheel} from 'swiper/modules';

const SWIPER_PAGES_PORTRAIT = document.querySelectorAll('[data-pages-swiper-portrait]');
const SWIPER_PAGES_LANDSCAPE = document.querySelectorAll('[data-pages-swiper-landscape]');

const SWIPER_COUNTERS = document.querySelectorAll('[data-counters-swiper]');

const MAIN_OPTIONS = {
  modules: [Navigation, Scrollbar, FreeMode, Mousewheel],
  direction: 'horizontal',
  on: {
    resize: function(swiper) {
      swiper.update();
    }
  },
  freeMode: {
    enabled: true,
    sticky: false,
    momentumBounce: false
  }
}

export function INIT_PAGES_SWIPER_PORTRAIT() {
  SWIPER_PAGES_PORTRAIT.forEach(SWIPER_ELEMENT => {

    const CLOSEST_PREV = SWIPER_ELEMENT.closest('section').querySelectorAll('button.is--prev') ?? '';
    const CLOSEST_NEXT = SWIPER_ELEMENT.closest('section').querySelectorAll('button.is--next') ?? '';

    const SLIDERS_PER_VIEW = SWIPER_ELEMENT.getAttribute('data-slides');

    const PAGES_OPTIONS = {
      ...MAIN_OPTIONS,
      spaceBetween: 16,
      navigation: {
        nextEl: [...CLOSEST_NEXT],
        prevEl: [...CLOSEST_PREV]
      },

      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 1.5
        },
        768: {
          spaceBetween: 16,
          slidesPerView: 2.25,
        },
        992: {
          spaceBetween: 16,
          slidesPerView: 2.25,
          //slidesOffsetBefore: 40, TODO: Ask Mo
          freeMode: false,

        },
        1200: {
          spaceBetween: 16,
          slidesPerView: SLIDERS_PER_VIEW,
          allowTouchMove: false,
          freeMode: {
            enabled: true,
            sticky: false,
            momentumBounce: false
          },
          mousewheel: {
            enabled: true,
            forceToAxis: true
          }
        }
      }
    };

    const PAGES_SWIPER = new Swiper(SWIPER_ELEMENT, PAGES_OPTIONS);
  })
}

export function INIT_PAGES_SWIPER_LANDSCAPE() {
  SWIPER_PAGES_LANDSCAPE.forEach(SWIPER_ELEMENT => {

    const CLOSEST_PREV = SWIPER_ELEMENT.closest('section').querySelectorAll('button.is--prev') ?? '';
    const CLOSEST_NEXT = SWIPER_ELEMENT.closest('section').querySelectorAll('button.is--next') ?? '';

    const SLIDERS_PER_VIEW = SWIPER_ELEMENT.getAttribute('data-slides');

    const PAGES_OPTIONS = {
      ...MAIN_OPTIONS,
      spaceBetween: 16,
      navigation: {
        nextEl: [...CLOSEST_NEXT],
        prevEl: [...CLOSEST_PREV]
      },

      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 1.1
        },
        768: {
          spaceBetween: 16,
          slidesPerView: 1.5,
        },
        992: {
          spaceBetween: 16,
          slidesPerView: 1.5,
          freeMode: false,

        },
        1200: {
          spaceBetween: 16,
          slidesPerView: SLIDERS_PER_VIEW,
          allowTouchMove: false,
          freeMode: {
            enabled: true,
            sticky: false,
            momentumBounce: false
          },
          mousewheel: {
            enabled: true,
            forceToAxis: true
          }
        }
      }
    };

    const PAGES_SWIPER = new Swiper(SWIPER_ELEMENT, PAGES_OPTIONS);
  })
}

export function INIT_COUNTERS_SWIPER() {
  SWIPER_COUNTERS.forEach(SWIPER_ELEMENT => {

    const CLOSEST_PREV = SWIPER_ELEMENT.closest('section').querySelectorAll('button.is--prev') ?? '';
    const CLOSEST_NEXT = SWIPER_ELEMENT.closest('section').querySelectorAll('button.is--next') ?? '';

    const SLIDES_PER_VIEW = SWIPER_ELEMENT.getAttribute('data-slides');

    const COUNTS_OPTIONS = {
      ...MAIN_OPTIONS,
      spaceBetween: 16,
      navigation: {
        nextEl: [...CLOSEST_NEXT],
        prevEl: [...CLOSEST_PREV]
      },
      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 1.175
        },
        576: {
          spaceBetween: 16,
          slidesPerView: 1.625
        },
        992: {
          spaceBetween: 16,
          slidesPerView: 2,
          freeMode: false,
        },
        1200: {
          allowTouchMove: false,
          slidesPerView: 2,
          mousewheel: {
            enabled: true,
            forceToAxis: true
          }
        }
      }
    };

    const COUNTER_SWIPER = new Swiper(SWIPER_ELEMENT, COUNTS_OPTIONS);
  })
}



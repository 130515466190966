import Rellax from 'rellax'

export function INIT_RELLAX() {
  document.querySelectorAll('.has--rellax').forEach(RELLAX => {
    new Rellax(RELLAX, {
      breakpoints: [576, 768, 1201],
      speed: .5,
      center: true
    })
  })
}
